@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .custom-container {
    @apply px-6 md:px-10 xl:px-20 mx-auto max-w-[1024px];
  }

  .side-section-width {
    @apply w-[360px];
  }

  .main-section-width {
    @apply w-[100%];
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5f5f5f;
}
